



























































































































































import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import { quillEditor, Quill } from "vue-quill-editor";
import "quill-mention";
import moment, { Moment } from "moment";
import { dispatch, Task, Media, User, TasksTag, Group } from "@/store";

const Inline = Quill.import("blots/inline");
const Parchment = Quill.import("parchment");

const ATTRIBUTES = ["href", "rel", "target", "download", "class"];

const LinkClass = new Parchment.Attributor.Class("link", "ql-link", {
  scope: Parchment.Scope.INLINE,
  whitelist: ["file"]
});

class InternalLink extends Inline {
  static create(value: any) {
    const node = super.create(value);
    value.href && node.setAttribute("href", value.href);
    value.rel && node.setAttribute("rel", value.rel);
    value.target && node.setAttribute("target", value.target);
    value.download && node.setAttribute("download", value.download);
    return node;
  }

  static formats(domNode: any) {
    return ATTRIBUTES.reduce((formats: any, attribute: any) => {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
}

InternalLink.blotName = "file";
InternalLink.className = "file";
InternalLink.tagName = "A";

Quill.register({
  "attributors/class/link": LinkClass,
  "formats/internal_link": InternalLink
});

export default Vue.extend({
  components: {
    quillEditor
  },
  data() {
    return {
      addRange: null as any,
      medias: [] as Media[],
      content: "" as any,
      time: ["", ""] as any,
      task: {
        tags: [] as TasksTag[],
        title: "",
        status: "processing",
        operation: "manual",
        vote_conf: {
          enable: false,
          is_multi: true,
          max_num: "1"
        },
        vote_content: {
          show_res: false,
          vote_list: [{ content: "", pic: "" }],
          vote_total_num: 0
        }
      } as Task,
      group: null,
      loading: false
    };
  },
  computed: {
    ...mapGetters({ allow: "allow" }),
    ...mapState({ user: "user" }),
    ...mapState({ app: "app" }),
    ...mapState("app", {
      isCN: "isCN",
      language: "language",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      images: "images"
    }),
    ...mapState("tasks", {
      categoryList: "categoryList",
      tagList: "tagList",
      chainList: "chainList"
    }),
    id(): number {
      return Number(this.$route.params.id);
    },
    isAdd(): boolean {
      return !this.id;
    },
    editor(): any {
      return (this.$refs.editor as any).quill;
    },
    editorOption(): any {
      return {
        placeholder: this.$t("task.content"),
        modules: {
          toolbar: [
            { header: [1, 2, 3, 4, 5, 6, false] },
            "bold",
            "italic",
            "underline", // toggled buttons
            // ["strike", "blockquote", "code-block"],

            // [{ header: 1 }, { header: 2 }], // custom button values

            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
            { align: [] }, // outdent/indent
            // [{ script: "sub" }, { script: "super" }], // superscript/subscript
            // [{ direction: "rtl" }], // text direction

            // [{ size: ["small", false, "large", "huge"] }], // custom dropdown

            // [{ font: [], { background: [] } }],

            {
              background: [
                "transparent",
                "rgb(255, 255, 255)",
                "rgb(251, 191, 188)",
                "rgba(254, 212, 164)",
                "rgba(255, 246, 122)",
                "rgba(183, 237, 177)",
                "rgba(186, 206, 253)",
                "rgb(187, 191, 196)",
                "rgb(247, 105, 100)",
                "rgb(255, 165, 61)",
                "rgb(255, 233, 40)",
                "rgb(98, 210, 86)",
                "rgba(78, 131, 253)",
                "rgba(147, 90, 246)"
              ]
            },
            {
              color: [
                "rgb(255, 255, 255)",
                "rgb(251, 191, 188)",
                "rgba(254, 212, 164)",
                "rgba(255, 246, 122)",
                "rgba(183, 237, 177)",
                "rgba(186, 206, 253)",
                "rgba(205, 178, 250)",
                "rgb(187, 191, 196)",
                "rgb(247, 105, 100)",
                "rgb(255, 165, 61)",
                "rgb(255, 233, 40)",
                "rgb(98, 210, 86)",
                "rgba(78, 131, 253)",
                "rgba(147, 90, 246)"
              ]
            }, // dropdown with defaults from theme
            "link",
            "image",
            "video",
            "file",
            "clean"
          ],
          mention: {
            allowedChars: /^\S*$/,
            mentionDenotationChars: ["@", "#"],
            showDenotationChar: false,
            source: async (
              searchTerm: string,
              renderList: any,
              mentionDenotationChar: string
            ) => {
              if (
                mentionDenotationChar === "@" &&
                this.allow("user", "retrieve") &&
                this.allow("task", "mention")
              ) {
                // dispatch.userGetInHouseUserIds().then(ids => {
                dispatch
                  .userGetList({
                    // ids,
                    page_size: 100,
                    page_number: 1,
                    nickname__icontains: searchTerm,
                    type: "task"
                  })
                  .then(res => {
                    renderList(
                      res.results.map((user: User) => ({
                        ...user,
                        avatar: user.avatar || this.images.avatar,
                        value: "@" + user.nickname
                      }))
                    );
                  });
                // });
              }
              if (mentionDenotationChar === "#") {
                renderList(
                  this.tagList
                    .filter((tag: TasksTag) =>
                      (this.isCN ? tag.name : tag.name)?.includes(searchTerm)
                    )
                    .map((tag: TasksTag) => ({
                      ...tag,
                      value: "#" + (this.isCN ? tag.name : tag.name)
                    }))
                );
              }
            },
            renderItem: (data: any) => {
              if (data.nickname) {
                return `<img src="${data.avatar}"/>${data.nickname}`;
              }
              if (data.name) {
                return "#" + (this.isCN ? data.name : data.name);
              }
            }
          }
        }
      };
    }
  },
  methods: {
    getSignImages() {
      const fileList = this.medias.map(media => media.url);
      const imageList =
        this.content.match(/https?:\/\/((?!").)+?aliyuncs.+?"/g) || [];
      imageList.map((image: string) => {
        fileList.push(image.replace('"', ""));
      });
      if (fileList.length) {
        dispatch.filesGet(fileList, this.id).then(res => {
          res.map((item: any) => {
            this.content = this.content.replace(
              item.request_url,
              item.sign_url
            );
            this.medias.map(media => {
              media.url = media.url.replace(item.request_url, item.sign_url);
            });
          });
        });
      }
    },
    onTagClick(tag: TasksTag) {
      const index = this.task.tags
        ? this.task.tags.findIndex(t => t.tag_id === tag.id)
        : -1;
      if (index === -1) {
        this.task.tags?.push({
          ...tag,
          tag_id: tag.id,
          tag_name: tag.name
        });
      } else {
        this.task.tags?.splice(index, 1);
      }
    },
    onCoverClick() {
      const cover = (this.$refs.cover as any).$el.querySelector("input");
      if (cover) {
        cover.click();
      }
    },
    onCoverUploaded(media: Media) {
      this.medias = [media];
    },
    onImagePasted(image: HTMLImageElement, delta: any) {
      if (image.src.includes(";base64,")) {
        const arr = image.src.split(",") as any;
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let length = bstr.length;
        const u8arr = new Uint8Array(length);

        while (length--) {
          u8arr[length] = bstr.charCodeAt(length);
        }

        const file = new File([u8arr], mime.replace("/", "."), { type: mime });

        this.$message.info({
          content: this.$t("upload.loading") + "",
          duration: 60000
        });

        dispatch
          .filesUpload(file)
          .then((url: string) => {
            this.$message.destroy();
            this.$message.info("" + this.$t("upload.success"));
            this.addRange = this.editor.getSelection();
            const index = this.addRange ? this.addRange.index : 0;
            this.editor.insertEmbed(index, "image", url);
          })
          .catch(() => {
            this.$message.destroy();
            this.$message.info("" + this.$t("upload.fail"));
          });

        delta.ops = [];
      }
      return delta;
    },
    onTextPasted(node: any, delta: any) {
      const regex = /https?:\/\/[^\s]+/g;
      if (regex.exec(node.data) != null) {
        delta.ops = [{ insert: node.data, attributes: { link: node.data } }];
      }
      return delta;
    },
    onImageClick(state: boolean) {
      this.addRange = this.editor.getSelection();
      if (state) {
        const upload = (this.$refs.imageUpload as any).$el.querySelector(
          "input"
        );
        if (upload) {
          upload.click();
        }
      }
    },
    onImageUploaded({ url }: { url: string }) {
      const index = this.addRange ? this.addRange.index : 0;
      this.editor.insertEmbed(index, "image", url);
    },
    onVideoClick(state: boolean) {
      this.addRange = this.editor.getSelection();
      if (state) {
        const upload = (this.$refs.videoUpload as any).$el.querySelector(
          "input"
        );
        if (upload) {
          upload.click();
        }
      }
    },
    onVideoUploaded({ url }: { url: string }) {
      const index = this.addRange ? this.addRange.index : 0;
      this.editor.insertEmbed(index, "video", url);
    },
    onFileClick(state: boolean) {
      this.addRange = this.editor.getSelection();
      if (state) {
        const upload = (this.$refs.fileUpload as any).$el.querySelector(
          "input"
        );
        if (upload) {
          upload.click();
        }
      }
    },
    onFileUploaded({ url, name }: { url: string; name: string }) {
      const index = this.addRange ? this.addRange.index : 0;
      this.editor.insertText(index, name, "file", {
        href: url,
        download: name,
        rel: "noopener noreferrer",
        target: "_blank"
      });
    },
    onEditorChange({ html }: { html: string }) {
      this.content = html;
    },
    onCoinInput(coin: number) {
      this.task.max_reward_number = Math.floor(Math.abs(coin));
    },
    disabledDate(current: Moment) {
      return current && current < moment().startOf("day");
    },
    onSubmit() {
      const { medias, time, task } = this;
      if (!this.task.tags?.length) {
        this.$message.info(
          "" + this.$t("selectError", [this.$t("task.category")])
        );
        return false;
      }
      // if (!task.tags?.length) {
      //   this.$message.info(
      //     "" + this.$t("selectError", [this.$t("task.category")])
      //   );
      //   return false;
      // }
      if (!task.title.trim()) {
        this.$message.info("" + this.$t("inputError", [this.$t("task.title")]));
        return false;
      }
      if (task.vote_conf.enable) {
        if (task.vote_content.vote_list.find(i => !i.content.trim())) {
          this.$message.info(
            "" + this.$t("inputError", [this.$t("task.voteContent")])
          );
          return false;
        }
        if (!time[0]) {
          this.$message.info(
            "" + this.$t("selectError", [this.$t("task.startAt")])
          );
          return false;
        }
      }

      this.loading = true;

      const mentionUserIds: number[] = [];

      const content = this.content.replace(
        /<span class="mention"((?!contenteditable).)*?<\/span>/,
        ""
      );

      document.querySelectorAll(".ql-editor .mention").forEach(mention => {
        if (mention.querySelector("span")) {
          const id = mention.getAttribute("data-id");
          const value = mention.getAttribute("data-value");
          if (!id) return;
          if (value?.startsWith("@") && !mentionUserIds.includes(Number(id))) {
            mentionUserIds.push(Number(id));
          }
        }
      });

      const data: Task = {
        ...task,
        title: task.title.trim(),
        medias,
        content,
        start_at: time[0] || null,
        end_at: time[1] || null,
        mention_user_ids: mentionUserIds,
        language: task.language || this.language,
        status: "processing"
      };

      if (this.isAdd) {
        dispatch
          .tasksPost(data)
          .then(res => {
            this.$message.info("" + this.$t("task.createSuccess"));
            setTimeout(() => {
              this.$router.push(this.$paths.dao + res.id + "/");
            }, 1500);
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        dispatch
          .tasksPatch(data)
          .then(() => {
            this.$message.info("" + this.$t("task.editSuccess"));
            setTimeout(() => {
              this.$router.push(this.$paths.dao + this.id + "/");
            }, 1500);
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    onDelete() {
      this.$confirm({
        title: this.$t("task.deleteConfirm"),
        centered: true,
        okText: "" + this.$t("task.ok"),
        cancelText: "" + this.$t("task.cancel"),
        onOk: () => {
          dispatch.tasksDelete(this.task).then(() => {
            this.$message.info("" + this.$t("task.deleteSuccess"));
            this.$emit("change");
            setTimeout(() => {
              this.$router.push(this.$paths.dao);
            }, 1500);
          });
        }
      });
    }
  },
  watch: {
    ["task.tags"](tags: TasksTag[]) {
      const tagIds = tags.map(i => i.tag_id || 0);
      dispatch.tasksTagTips(tagIds).then(res => {
        if (res.category_id) this.task.task_category_id = res.category_id;
      });
      dispatch.tasksGetGroupIds(tagIds).then(res => {
        if (res.group_ids) {
          this.group = this.user.groupList.filter((group: Group) =>
            res.group_ids.includes(group.id)
          );
        }
      });
    }
  },
  mounted() {
    window.addEventListener(
      "mention-clicked",
      (event: any) => {
        if (event.value.value?.includes("@"))
          window.open(this.$paths.users + event.value.id, "_blank");
        if (event.value.value?.includes("#"))
          window.open(this.$paths.dao + "?tag_ids=" + event.value.id, "_blank");
      },
      false
    );
    this.editor.getModule("toolbar").addHandler("image", this.onImageClick);
    this.editor.getModule("toolbar").addHandler("video", this.onVideoClick);
    const fileButton: any = document.querySelector(".ql-file");
    fileButton.className = `${fileButton.className} iconfont`;
    fileButton.addEventListener("click", this.onFileClick);
    this.editor.getModule("clipboard").addMatcher("img", this.onImagePasted);
    this.editor
      .getModule("clipboard")
      .addMatcher(Node.TEXT_NODE, this.onTextPasted);
    if (this.id) {
      dispatch.tasksGet(this.id).then((task: Task) => {
        this.medias = task.medias || [];
        this.content = task.content;
        this.time = [task.start_at, task.end_at];
        this.task = task;
        this.getSignImages();
      });
    }
  }
});
